.page11 {
  width: 100%;
  height: 85vh;
  /* background-color: whitesmoke; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main11 {
  width: 41.875rem;
  height: 18.4375rem;
  background-color: #072137;
  font-family: 'Questrial', sans-serif;
  position: relative;
  z-index: 1;
}

.product-container1 {
  width: 21.25rem;
  height: 17.1875rem;
  background-color: #FFB000;
  position: relative;
  top: -2.7rem;
  left: -4.4rem;
  overflow: hidden;
}


.product-container1 h2 {
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
}

.product-container1 img {
  width: 100%;
  padding: 10px;
  height: 80%;
  /* object-fit: cover; */
}
/* 
.card {
  width: 17.5rem;
  height: 11rem;
  background-color: #F7FBFC;
  border-radius: 0.7rem;
  padding: 1.3rem 1.6rem;
  position: absolute;
  top: -2.75rem;
  right: 4.4rem;
}

.card::after {
  content: '';
  display: block;
  width: 17.5rem;
  height: 11rem;
  background-color: #2A2A2A;
  border-radius: 0.7rem;
  position: absolute;
  top: 2.7rem;
  right: -1.5rem;
  transform: rotate(8deg);
  z-index: -1;
}

.chip {
  width: 3rem;
  height: 2.2rem;
  margin-bottom: 0.7rem;
}

.card form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.card label {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.55rem;
  color: #C2C2C2;
  margin-top: 0.35rem;
}

.card label[for="number"] {
  width: 72%;
}

.card label[for="date"] {
  width: 22%;
  margin-left: 6%;
}

.card label[for="cvc"] {
  width: 15%;
  position: absolute;
  right: 7%;
  bottom: -3.9rem;
}

.card label[for="remember"] {
  width: auto;
  height: 2.8rem;
  font-size: 0.7rem;
  position: absolute;
  left: -1.5rem;
  bottom: -10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

.card label[for="remember"] input {
  margin-right: 0.5rem;
  filter: invert(100%) hue-rotate(30deg) brightness(1.7);
  color: red;
  height: 1rem;
  width: 1rem;
}

.card input {
  border: none;
  border-bottom: 1px solid #C2C2C2;
  outline: none;
  background-color: transparent;
  font-size: 1.1rem;
}

.card input#cvc {
  color: white;
} */

.buyNow {
  height: 2.8rem;
  width: 11.8rem;
  font-size: 0.8rem;
  position: absolute;
  bottom: 1.4rem;
  left: 31rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.paylater-cancel-button {
  width: 50px;
  position: absolute;
  right: 0;
  background: "#fff";
}
/* .card button {
  height: 2.8rem;
  width: 11.8rem;
  font-size: 0.8rem;
  position: absolute;
  bottom: -10rem;
  left: 10rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} */

.buyNow .fa-angle-right {
  font-size: 2rem;
  position: absolute;
  left: 72%;
}
/* .card button .fa-angle-right {
  font-size: 2rem;
  position: absolute;
  left: 72%;
} */

.price-container {
  position: absolute;
  bottom: 0.6rem;
  left: 3.4rem;
  display: inline-block;
}

.price-container strong {
  font-size: 2.2rem;
  color: white;
}

.price-container small {
  font-size: 0.6rem;
  color: #C2C2C2;
}

.paylater-prices {
  font-size: 1.1em;

}

.paylater-total-price > b {
  font-size: 41px;
}

@media screen and (max-width: 992px) {
  .buyNow {
    height: 2.8rem;
    width: 11.8rem;
    font-size: 0.8rem;
    position: absolute;
    bottom: 1.4rem;
    left: 18rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@media (max-width: 1000px) {
  .paylater-prices {
    font-size: 0.9rem;
  }
  .paylater-total-price > b {
    font-size: 25px;
  }
  .buyNow {
    left: 20.5rem;
  }
}

@media (max-width: 664px) {
  .product-container1 {
    left: -5.75rem;
    top: 0;
    height: 18.39rem;
  }
  .buyNow {
    left: 3.4rem;
    top: 15rem;
  }
  .main11 {
    width: 10rem;
  }
  .paylater-prices {
    display: none;
  }
  .price-container strong {
    display: none;
  }
  .paylater-cancel-button {
    width: 35px;
    left: 13.35rem;
    top: -0.05rem;
    z-index: 1;
  }
}

@media (max-width: 347px) {
  .product-container1 {
    width: 18rem;
    height: 17rem;
    margin-left: -50.5px;
  }
  .buyNow {
    left: -3rem;
    top: 14rem;
  }
  .main11 {
    height: 0.1px;
    width: 0.1px;
  }
  .paylater-cancel-button {
    left: 6.9rem;
    top: 0;
    z-index: 1;
  }
}






/* NOW Payment */




.container33 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card33 {
  width: 500px;
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
  border-top: 4px solid #c84adf;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form33 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input33 {
  padding: 10px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ccc;
  color: #333;
  margin-bottom: 20PX;
}

.buttons33 {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.login-button33 {
  padding: 10px 20px;
  background-color: #c84adf;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-button33 {
  padding: 10px 20px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .card33 {
    width: 100%;
    max-width: 300px;
  }
}









