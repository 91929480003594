.countdown-timer {
    text-align: center;
  }
  
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* flex-wrap: wrap; */
  }
  
  .countdown-item {
    margin: 0 10px;
    text-align: center;
  }
  
  .countdown-value {
    display: block;
    font-size: 14px;
    font-weight: bold;
  }
  
  .countdown-label {
    font-size: 10px;
    color: #888;
  }
  
  .timer-expired {
    font-size: 18px;
    font-weight: bold;
    color: red;
    margin-top: 20px;
  }