.expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }
  
  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }
  
  .expired-notice > p {
    font-size: 1.5rem;
  }
  
  .show-counter {
    /* padding: 0.5rem; */
    font-size: 800px;
  }
  
  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 2.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    /* border: 1px solid #ebebeb; */
    border-radius: 0.25rem;
    text-decoration: none;
    /* color: #000; */
    /* background: #fff; */
    margin: 0 10px;
    border-radius: 10px;
    padding: 10px 0;
  }
  
  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .show-counter .countdown.danger {
    color: #ff0000;
  }
  
  .show-counter .countdown > p {
    margin: 0;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.85rem;
    line-height: 1rem;
  }

  

  .dark_mode_timer_num{
    color:#fff;
  }

  .timer_num{
    color:#191919;
  }

  .dark_mode_timer_bg{
    background: #112237;

  }


  .timer_bg{
    background: #fff;

  }


  .dark_mode_timer_bg>p{
    color:#fff;

  }


  .timer_bg>p{
    color: #191919;

  }

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');

.modal-popup {
  background: url(https://rvs-newsletter-popup.vercel.app/Assets/Background.png);
  position: absolute;
    top: 43%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 700px;
    /* background: #fff; */
    border-radius: 5px;
    overflow: hidden;
    z-index: 99;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Work Sans', sans-serif;
    position: absolute;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.modal-popup .popup-header {
    width: 100%;
    padding: 60px 0px;
    display: flex;
    justify-content: center;
    background: #2355f2 url(https://rvs-newsletter-popup.vercel.app/Assets/Pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.modal-popup .popup-header h1 {
    width: 70%;
    text-align: center;
    color: #fff;
    font-size: 34px;
    line-height: 42px;
    font-weight: 600;
}
.modal-popup .popup-body {
    width: 100%;
    padding: 25px 0px;
    display: flex;
    justify-content: center;
}
.modal-popup .popup-body .popup-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}
.modal-popup .popup-body .popup-content .popup-txt, .modal-popup .popup-body .popup-profile .profile-txt p {
    width: 90%;
    color: #a3a3a3;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
}
.modal-popup .popup-body .popup-content .popup-form {
    width: 100%;
    display: flex;
    column-gap: 10px;
    margin-top: 15px;
}
.modal-popup .popup-body .popup-content .popup-form input, .modal-popup .popup-body .popup-content .popup-form button {
    padding: 15px;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
    min-width: 100px;
}
.modal-popup .popup-body .popup-content .popup-form input {
    width: 85%;
    border: 2px solid #e9eaf1;
    background-color: #f5f7fa;
    color: #a3a3a3;
}
.modal-popup .popup-body .popup-content .popup-form button {
    cursor: pointer;
    background-color: #2355f2;
    border: 0px;
    color: #fff;
    width: 100px;
}
.modal-popup .popup-body .popup-content .popup-alt-txt {
    width: 100%;
    color: #a3a3a3aa;
    line-height: 20px;
    text-align: start;
    font-weight: 500;
}
.modal-popup .popup-body .popup-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    margin-top: 20px;
}
.modal-popup .popup-body .popup-profile .profile-content {
    display: flex;
    column-gap: 12px;
    align-items: center;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-img img {
    width: 50px;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-details h4 {
    font-weight: 600;
}
.modal-popup .popup-body .popup-profile .profile-content .profile-details p {
    color: #a3a3a3;
    font-size: 14px;
}

.wallet_notification_close_button {
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: -12%;
  left: 94%;
  appearance: none;
  background: none;
  font-size: 30px;
  font-weight: bold
}

@media only screen and (max-width: 870px) {
  .modal-popup {
    top: 20%;
    margin-left: 4%;
    width: 80%;
  }
 .wallet_notification_close_button {
  left: 89%;
 }
}

@media only screen and (max-width: 390px) {
  .modal-popup {
      top: 20%;
      margin-left: 4%;
      width: 80%;
  }
  .wallet_notification_close_button {
    left: 89%;
  }
}