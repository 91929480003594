.recharge-submit-button {
    background-color:#54499D;
    background-size: 0% 100%;
    border:none;
    border-radius:4px;
    color:#fff;
    display:inline-block;
    font-size:20px;
    outline:none;
    padding:4px 0;
    text-align:center;
    text-decoration:none;
    transition: background-color .5s;
    width:100px;
}

.recharge-submit-button:hover {
    background-color:#54499D;
    background-image:linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,.7) 100%);
    background-repeat:no-repeat;
    background-size: 200% 100%; 
    transition:background-size 1s, background-color 1s;
  }
          
.recharge-submit-button:active {background-color:#2980b9; transition:background-size 0}

.recharge-loading {
    top: 500px;
}

